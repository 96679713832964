import * as React from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';

export const Locations: React.FC = () => (
    <Container>
        <Row className="justify-content-md-center">
            <Col lg="6">
                <h1>Locations</h1>
                <hr />
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col md="3" className='location-info'>
                <div>
                    <h4>Jacksboro, TN</h4>
                    <p>2702 Jacksboro Pike<br />Jacksboro, TN 37757</p>
                </div>
                <div>
                    <Button onClick={() => { document.location.href = 'https://goo.gl/maps/s2h8ZDZtxfeJH2546'; }}>Get Directions</Button>
                </div>
                <hr />
            </Col>
            <Col md="3" className='location-info'>
                <div>
                    <h4>Clinton, TN</h4>
                    <p>120 Tanner Lane<br />Clinton, TN 37716</p>
                </div>
                <div>
                    <Button onClick={() => { document.location.href = 'https://goo.gl/maps/9WcFkdQETSFppZKX8'; }}>Get Directions</Button>
                </div>
                <hr />
            </Col>
        </Row>
    </Container>
);
