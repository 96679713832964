import React from 'react';
import { Nav, NavDropdown, Navbar as BootstrapNav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteModel } from '../models/routes';
import logo from '../images/logo.jpg';

export const Navbar: React.FC = () => (
  <BootstrapNav collapseOnSelect fixed='top' expand='lg' variant='dark'>
    <LinkContainer to={RouteModel.HOME}>
      <BootstrapNav.Brand>
        <img src={logo} alt='logo' style={{ height: 50 }} />
      </BootstrapNav.Brand>
    </LinkContainer>
    <BootstrapNav.Toggle aria-controls='responsive-navbar-nav' />
    <BootstrapNav.Collapse id='responsive-navbar-nav'>
      <Nav className='mr-auto'></Nav>
      <Nav>
        <LinkContainer to={RouteModel.HOME}>
          <Nav.Link>Home</Nav.Link>
        </LinkContainer>
        <LinkContainer to={RouteModel.LOCATIONS}>
          <Nav.Link>Locations</Nav.Link>
        </LinkContainer>
        <LinkContainer to={RouteModel.SERVICES}>
          <Nav.Link>Services</Nav.Link>
        </LinkContainer>
        <NavDropdown title='Patients' id='basic-nav-dropdown'>
          <NavDropdown.Item href='https://ce4dcf2a-2652-485a-8a17-53613ce819f3.mypaysimple.com/s/patient-account-payment'>
            Make a Payment
          </NavDropdown.Item>
        </NavDropdown>
        {/* <LinkContainer to={RouteModel.TEAM}>
                    <Nav.Link>Team</Nav.Link>
                </LinkContainer> */}
        <LinkContainer to={RouteModel.CONTACT}>
          <Nav.Link>Contact</Nav.Link>
        </LinkContainer>
      </Nav>
    </BootstrapNav.Collapse>
  </BootstrapNav>
);
