import * as React from 'react';

export const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <div className='container'>
        <span className='text-muted'>
          <div className='row'>
            <div className='col-sm-8'>
              <p>© {year} Neighborhood Urgent Care</p>
            </div>
          </div>
        </span>
      </div>
    </footer>
  );
};
