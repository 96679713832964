import * as React from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteModel } from '../../models/routes';
import HeaderImage from '../../images/header.jpg';

export const Home: React.FC = () => (
  <Container>
    <div className='home-header'>
      <img src={HeaderImage} alt='Convenient care for the whole family' />
    </div>
    <Row className='justify-content-md-center'>
      <Col md='6'>
        <h2>Hours</h2>
        <br />
      </Col>
    </Row>
    <Row className='justify-content-md-center section'>
      <Col md='2'>
        <b>Sunday</b>
        <p>9AM to 3PM</p>
      </Col>
      <Col md='2'>
        <b>Mon-Thu</b>
        <p>7AM to 7PM</p>
      </Col>
      <Col md='2'>
        <b>Friday</b>
        <p>7AM to 5PM</p>
      </Col>
    </Row>
    <Row className='justify-content-md-center'>
      <Col md='6'>
        <h2>Locations</h2>
        <br />
      </Col>
    </Row>
    <Row className='justify-content-md-center section'>
      <Col md='3' className='location-info'>
        <div>
          <h4>Jacksboro, TN</h4>
          <p>
            2702 Jacksboro Pike
            <br />
            Jacksboro, TN 37757
          </p>
        </div>
        <div>
          <Button
            onClick={() => {
              document.location.href = 'https://goo.gl/maps/s2h8ZDZtxfeJH2546';
            }}>
            Get Directions
          </Button>
        </div>
        <hr />
      </Col>
      <Col md='3' className='location-info'>
        <div>
          <h4>Clinton, TN</h4>
          <p>
            120 Tanner Lane
            <br />
            Clinton, TN 37716
          </p>
        </div>
        <div>
          <Button
            onClick={() => {
              document.location.href = 'https://goo.gl/maps/9WcFkdQETSFppZKX8';
            }}>
            Get Directions
          </Button>
        </div>
        <hr />
      </Col>
    </Row>
    <br />
    <Row className='justify-content-md-center section'>
      <Col md='6' className='services-info'>
        <h2>Services</h2>
        <p>A truncated description of the services offered at the clinics with a link to view more.</p>
        <LinkContainer to={RouteModel.SERVICES}>
          <button>View all services</button>
        </LinkContainer>
      </Col>
    </Row>
  </Container>
);
