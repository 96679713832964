import * as React from 'react';
import { Button, Container, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { RouteModel } from '../../models/routes';
import { ContactFormModel } from '../../models/contact-form';
import { default as axios } from 'axios';

export const Contact: React.FC = () => {
  const history = useHistory();

  const [fullName, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const from = 'info@neighborhooduc.com';
  const toEmail = 'info@neighborhooduc.com';
  // const toEmail = 'evan.s.richards@gmail.com';
  const subject = `New message from the Neighborhood Urgent Care website contact form.`;
  const fullMessage = `
    Name: ${fullName}
    Email: ${email}
    Message: ${message}`;

  // eslint-disable-next-line
  const validateEmail = (email: string) =>
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/.test(email) ? true : false;

  const sendMessage = () => {
    try {
      setLoading(true);
      let firebaseFunctionUrl: string =
        'https://us-central1-neighborhood-urgent-care.cloudfunctions.net/sendContactEmail';
      let contactForm: ContactFormModel = {
        fromName: fullName,
        fromEmail: from,
        replyTo: email,
        toEmail,
        subject,
        message: fullMessage,
      };
      axios
        .post(firebaseFunctionUrl, contactForm)
        .then((res: any) => {
          setLoading(false);
          alert('Your message sent successfully!');
          setTimeout(() => history.push(RouteModel.HOME), 1000);
        })
        .catch((e: any) => {
          setLoading(false);
          alert(e);
        });
    } catch (e) {
      alert(e);
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = React.useState(false);

  const isValid = fullName.length > 0 && validateEmail(email) && message.length > 0;

  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col lg='4'>
          <Form className='content'>
            <h1>Contact us</h1>
            <hr />
            <h4 style={{ marginBottom: 15 }}>Call us</h4>
            <Button
              variant='primary'
              onClick={() => {
                document.location.href = 'tel:4232019937';
              }}
              style={{ marginBottom: 15, width: '100%' }}>
              Call General (423.201.9937)
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                document.location.href = 'tel:4236581406';
              }}
              style={{ marginBottom: 15, width: '100%' }}>
              Call Billing (423.658.1406)
            </Button>
            <hr />
            <h4>Send us a message</h4>
            <FormGroup>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name='fullName'
                value={fullName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name='emailAddress'
                type='email'
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                style={{ resize: 'none' }}
                value={message}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              />
            </FormGroup>
            <Button variant='primary' type='submit' onClick={sendMessage} disabled={!isValid || isLoading}>
              {isLoading ? 'Sending...' : 'Send Message'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
