import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import { Contact, Home, Services, Locations } from '../../views';
import { RouteModel } from '../../models/routes';
import './App.css';

export const App: React.FC = () => (
  <Router>
    <div>
      <Navbar />
      <div className='app-container'>
        <Switch>
          <Route path={RouteModel.CONTACT} component={Contact} />
          <Route path={RouteModel.SERVICES} component={Services} />
          <Route path={RouteModel.LOCATIONS} component={Locations} />
          {/* <Route path={RouteModel.TEAM} component={Team} /> */}
          <Route path={RouteModel.HOME} component={Home} />
        </Switch>
      </div>
      <Footer />
    </div>
  </Router>
);
