import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

export const Services: React.FC = () => (
    <Container>
        <Row className="justify-content-md-center">
            <Col lg="8">
                <h1>Our Services</h1>
                <hr />
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col lg="4">
                <div className='section'>
                    <h3>Urgent Care</h3>
                    <p>When you’re sick or in need of medical care, you want to know there’s a place you can go. Our caring medical staff offers treatment for the flu, common cold, upper respiratory infections, allergies, sutures and more, in addition to X-rays and related services.</p>
                    <b>No appointment necessary for urgent care visits.  Come in now and feel better soon!</b>
                    <br /><br />
                    <h3>Primary Care</h3>
                    <p>Choose our high convenience primary care plan where we take the wait out of your primary care experience.</p>
                </div>
            </Col>
            <Col lg='4'>
                <div className='section'>
                    <h5>Our services include:</h5>
                    <ul>
                        <li>Children's health</li>
                        <li>Well-child care</li>
                        <li>Diabetes care</li>
                        <li>Sports and school physicals</li>
                        <li>Chronic and acute adult illnesses</li>
                        <li>Men’s health</li>
                        <li>Women’s health</li>
                    </ul>
                    <br />
                    <p>Call us today to set up a primary care visit.</p>
                    <br />
                    <button onClick={() => window.location.href="tel:4232019937"}>Call (423) 201-9937</button>
                </div>
            </Col>
        </Row>
    </Container>
);
